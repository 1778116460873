import React from 'react'
import HelmetComponent from '../components/HelmetComponent'

import '../components/style.scss'

class NotFoundPage extends React.Component {
  componentDidMount() {
    setTimeout(() => this.setState((window.location = '/')), 4000)
  }
  render() {
    return (
      <div className="notfound-container">
        <HelmetComponent />
        <iframe
          title="fail"
          width="300"
          height="300"
          src="https://lottiefiles.com/iframe/635-fail"
          frameBorder="0"
          allowFullScreen
        />
        <h1>
          Awww, nothing exists here{' '}
          <span role="img" aria-label="sad">
            😢
          </span>
        </h1>
        <p>It's okay, we'll take you back!</p>
      </div>
    )
  }
}

export default NotFoundPage
